import {
  i18n
} from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import IntegerField from '@/shared/fields/integer-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.request.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.request.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  requestNumber: new IntegerField('requestNumber', label('requestNumber'), {}),
  amount: new DecimalField('amount', label('amount'), {}),
  description: new StringField('description', label('description'), {}),
  walletId: new StringField('walletId', label('walletId'), {}),
  ownerId: new StringField('ownerId', label('ownerId'), {}),
  transactionId: new StringField('transactionId', label('transactionId'), {}),
  notes: new StringField('notes', label('notes'), {}),
  owner: new JsonField('owner', label('owner'), {}),

  ownerType: new EnumeratorField('ownerType', label('ownerType'), [
    { value: 'client', label: enumeratorLabel('userType', 'client'), i18n: `entities.request.enumerators.userType.client`},
    { value: 'guide', label: enumeratorLabel('userType', 'guide'), i18n: `entities.request.enumerators.userType.guide`},
    { value: 'company', label: enumeratorLabel('userType', 'company'), i18n: `entities.request.enumerators.userType.company`},
  ],{}),
  type: new EnumeratorField('type', label('type'), [
    { value: 'withdraw', label: enumeratorLabel('type', 'withdraw'), i18n: `entities.request.enumerators.type.withdraw`},
  ],{}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending'), i18n: `entities.request.enumerators.status.pending`},
    { value: 'cancelled', label: enumeratorLabel('status', 'cancelled'), i18n: `entities.request.enumerators.status.cancelled`},
    { value: 'rejected', label: enumeratorLabel('status', 'rejected'), i18n: `entities.request.enumerators.status.rejected`},
    { value: 'accepted', label: enumeratorLabel('status', 'accepted'), i18n: `entities.request.enumerators.status.accepted`},
    { value: 'confirmed', label: enumeratorLabel('status', 'confirmed'), i18n: `entities.request.enumerators.status.confirmed`},
  ],{}),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class RequestModel extends GenericModel {
  static get fields() {
    return fields;
  }
}