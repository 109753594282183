//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RequestFormModal from '@/modules/request/components/request-form-modal';
import moment from 'moment';

import { RequestPermissions } from '@/modules/request/request-permissions';
import { RequestModel } from '@/modules/request/request-model';
const { fields } = RequestModel;

export default {
  name: 'app-request-list-table',

  props: {
    status: {
      type: String,
    },
  },
  components:{
    [RequestFormModal.name]:RequestFormModal
  },

  mounted() {
    this.doFetchFirstPage();
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: {
        status: '',
        date: '',
      },
      selectedId:'',
      modalType: "",
      modalVisible: false,
      dialogVisible: false,
      columns: [
        {
          name: 'requestNumber',
          field: 'requestNumber',
          label: 'entities.request.fields.requestNumber',
          align: 'center',
        },
        {
          name: 'name',
          field: 'owner',
          label: 'entities.request.fields.name',
          align: 'center',
        },
        {
          name: 'phoneNumber',
          field: 'owner',
          label: 'entities.request.fields.phoneNumber',
          align: 'center',
        },
        {
          name: 'type',
          field: 'type',
          label: 'entities.request.fields.type',
          align: 'center',
        },
        {
          name: 'description',
          field: 'description',
          label: 'entities.request.fields.description',
          align: 'center',
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'entities.request.fields.amount',
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.request.fields.createdAt',
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.action',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows:'request/list/rows',
      loading: 'request/list/loading',
      pagination: 'request/list/pagination',
      
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new RequestPermissions(this.currentUser).edit;
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.limit || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination['rowsPerPage']);
    },
  },
  methods: {
    ...mapActions({
      doMountTable: 'request/list/doMountTable',
      doFetch: 'request/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    
  //#region [ Presenter ]
    presenter(row, fieldName) {
      const val = RequestModel.presenter(row, fieldName);
      switch (fieldName) {
        case 'requestNumber':
          return this.currentLanguageCode == 'ar'
            ? `${val} #`
            : `# ${val}`
        case 'amount':
          return this.currentLanguageCode == 'ar'
            ? `${val} ريال سعودي ` 
            : `${val} SAR`  
        default:
          return val ? val : 'ـــ'
      }
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = RequestModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterOwner(row, fieldName, key) {
      const owner = RequestModel.presenter(row, fieldName);
      let fieldValue = owner && owner[key] ? owner[key] : null
      let value
      switch (key) {
        case 'name': 
          value = fieldValue && fieldValue[this.currentLanguageCode] ? fieldValue[this.currentLanguageCode] : 'ـــ'
          break
        case 'avatar':
          value = fieldValue
            ? fieldValue
            : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c';
          break
        default:
          value = fieldValue
          break
      }
      return value ? value : 'ـــ';
    },
  //#endregion

    doOpenModal(type) {
      this.dialogVisible = true;
      this.modalVisible = true;
      this.modalType = type
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    onModalSuccess() {
      this.successDialog = true
    },
    
    acceptRequest(id) {
      this.doOpenModal("accept")
      this.selectedId=id
    },
    rejectRequest(id) {
      this.doOpenModal("reject")
      this.selectedId=id
    },
    closeRequest(id) {
      this.doOpenModal("close")
      this.selectedId=id
    },

    async doFetchFirstPage() {
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
        },
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: firstDocument,
          action: 'prev'
        },
        keepPagination: true,
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: lastDocument,
          action: 'next'
        },
        keepPagination: true,
      });
    },

  },
};
