//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RequestListTable from '@/modules/request/components/request-list-table.vue';
// import RequestListFilter from '@/modules/request/components/request-list-filter.vue';
// import RequestListToolbar from '@/modules/request/components/request-list-toolbar.vue';

export default {
  name: 'app-request-list-page',

  components: {
    [RequestListTable.name]: RequestListTable,
    // [RequestListFilter.name]: RequestListFilter,
    // [RequestListToolbar.name]: RequestListToolbar,
  },

  data() {
    return {
      tab: 'new',
    };
  },
  methods: {
    ...mapActions({
      // doFetch: 'request/list/doFetch',
    }),
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
};
