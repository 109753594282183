import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class RequestPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.requestRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.requestImport,
    );
    this.requestAutocomplete = permissionChecker.match(
      Permissions.values.requestAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.requestCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.requestEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.requestDestroy,
    );
  }
}
