//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RequestRejectForm from '@/modules/request/components/request-reject-form';
import RequestCloseForm from '@/modules/request/components/request-close-form';
import RequestAcceptForm from '@/modules/request/components/request-accept-form';

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';

export default {
  name: 'app-request-form-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    type:{
      type: String
    },
    selectedId:{
      type:String
    }
  },

  components: {
    [RequestRejectForm.name]: RequestRejectForm,
    [RequestCloseForm.name]: RequestCloseForm,
    [RequestAcceptForm.name]: RequestAcceptForm
  },
  
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  data() {
    return {
      // successDialog: true,
    };
  },
  computed: {
    ...mapGetters({
      record: 'request/form/record',
      findLoading: 'request/form/findLoading',
      saveLoading: 'request/form/saveLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
  },

  methods: {
    ...mapActions({
      doFind: 'request/form/doFind',
      doNew: 'request/form/doNew',
      doUpdate: 'request/form/doUpdate',
      doCreate: 'request/form/doCreate',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmit() {
      this.dialogVisible = false;
    },
  },
};
